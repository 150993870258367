import deleteAllCookiesFactory from 'delete-all-cookies'
import enableAdroll from 'app/assets/javascripts/modules/online-bookings/enable-adroll'
import enableGoogleAnalytics from 'app/assets/javascripts/modules/online-bookings/enable-google-analytics'
import enableGoogleTagManager from 'app/assets/javascripts/modules/online-bookings/enable-google-tag-manager'
import { get as getCookieValue, set as setCookie } from 'cookies-js'
import $ from 'jquery'

(function () {
  var COOKIE_POLICY_VERSION = 1;
  var ONLINE_BOOKINGS_CONSENT_COOKIE = 'onlineBookingsLocalDataConsent';

  var deleteAllCookies = deleteAllCookiesFactory(window);

  var isEmbedded = $('body').hasClass('embedded');

  var enableAllCookieFunctionality = function () {
    if (window.googleTagManagerContainerId) enableGoogleTagManager(window.googleTagManagerContainerId);
    if (window.googleAnalyticsId) enableGoogleAnalytics(window.googleAnalyticsId);
    if (window.adroll_pix_id) enableAdroll();
  }

  var removeCookieBanner = function () {
    $('[data-js-cookie-banner]').remove();
  }

  var setConsentCookieGranted = function () {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    setCookie(ONLINE_BOOKINGS_CONSENT_COOKIE, COOKIE_POLICY_VERSION + '-true', { expires: date, path: '/' });
  }

  var setConsentCookieRejected = function () {
    setCookie(ONLINE_BOOKINGS_CONSENT_COOKIE, COOKIE_POLICY_VERSION + '-false', { path: '/' });
  }

  var isCookieConsentGranted = function () {
    return (getCookieValue(ONLINE_BOOKINGS_CONSENT_COOKIE) === COOKIE_POLICY_VERSION + '-true')
  }

  var isCookieConsentRejected = function () {
    return (getCookieValue(ONLINE_BOOKINGS_CONSENT_COOKIE) === COOKIE_POLICY_VERSION + '-false')
  }

  var toggleCookieConsentButtons = function () {
    if (isCookieConsentGranted()) {
      $('[data-js-reject-cookie-consent]').show()
      $('[data-js-grant-cookie-consent]').hide()
    } else if (isCookieConsentRejected()) {
      $('[data-js-reject-cookie-consent]').hide()
      $('[data-js-grant-cookie-consent]').show()
    } else {
      $('[data-js-reject-cookie-consent]').show()
      $('[data-js-grant-cookie-consent]').show()
    }
  }

  window.onCookieConsentGranted = function () {
    setConsentCookieGranted();
    enableAllCookieFunctionality();
    removeCookieBanner();
    toggleCookieConsentButtons();
  }

  window.onCookieConsentRejected = function () {
    deleteAllCookies();
    setConsentCookieRejected();
    removeCookieBanner();
    toggleCookieConsentButtons();
  }

  if (isEmbedded) {
    enableAllCookieFunctionality();
  } else if (isCookieConsentGranted()) {
    enableAllCookieFunctionality();
    removeCookieBanner();
  } else if (isCookieConsentRejected()) {
    onCookieConsentRejected();
  } else {
    deleteAllCookies()
    $('[data-js-cookie-banner]').show();
  }

  toggleCookieConsentButtons();
}());
