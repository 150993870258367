import { hideBookingError, showBookingError } from './booking_error'

class Bookings.Reservation
  constructor: (options) ->
    @appointment = options.appointment
    @bookingReservationMinutes = options.bookingReservationMinutes
    @reservationKey = $('[data-js-booking-reservation-key]').val()

  createNewReservation: ->
    reservationData = @reservationData()
    if $('#facebook_page_id').length == 1
      reservationData.facebook_page_id = $('#facebook_page_id').val()
    $.ajax '/bookings/reservations',
      type: 'POST'
      data: reservationData
      dataType: 'json'
      success: (data) =>
        @reservationKey = data.key
        $('[data-js-booking-reservation-key]').val @reservationKey
        hideBookingError()
      error: (response) =>
        if response.status == 422
          showBookingError(response.responseJSON.error + ' Please go back and try again.')
        else
          showBookingError('We were unable to reserve your spot, please go back and try again.')

  deleteReservationAndCreateNew: ->
    path = '/bookings/reservations/' + @reservationKey
    if $('#facebook_page_id').length == 1
      path = "#{path}?facebook_page_id=#{$('#facebook_page_id').val()}"
    $.ajax path,
      type: 'DELETE',
      dataType: 'json'
      success: =>
        @reservationKey = null
        $('[data-js-booking-reservation-key]').val ''
        @createNewReservation()

  generateAfterTimeSlotSelection: ->
    if @bookingReservationMinutes > 0
      if @reservationKey
        @deleteReservationAndCreateNew()
      else
        @createNewReservation()

  reservationData: ->
    if @appointment.appointmentId
      reservation:
        appointment_id: @appointment.appointmentId
      reservation_type: 'group'
    else
      appointment_start: @appointment.appointmentStart
      appointment_type_id: @appointment.appointmentTypeId
      reservation:
        business_id: @appointment.businessId
        practitioner_id: @appointment.practitionerId
      reservation_type: 'individual'
