class Bookings.PractitionersModal
  constructor: (options) ->
    @appointment = options.appointment
    @summaryDetails = options.summaryDetails

    $('[data-js-practitioners-selection-modal], [data-js-practitioners-selection-modal] [data-js-close-button]').on 'click', (e) =>
      e.preventDefault()
      e.stopPropagation()
      @closeModal()

    $('[data-js-time-slot-releted-practitioner-list]').on 'click', (e) =>
      e.preventDefault()
      e.stopPropagation()

    $('[data-js-time-slot-releted-practitioner-list]').on "mousedown keypress", ".button--reveal-description", (e) =>
      e.preventDefault()
      e.stopPropagation()
      $(e.currentTarget).parents().children('.module__description').toggle()
      $(e.currentTarget).closest('.button--reveal-description').toggleClass 'active'
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

    $('[data-js-time-slot-releted-practitioner-list]').on "mouseup", ".button--reveal-description", (e) =>
      e.preventDefault()
      e.stopPropagation()
      $(e.currentTarget).closest('.button--reveal-description').blur()

    $('[data-js-time-slot-releted-practitioner-list]').on 'click', ' [data-js-practitioner-select-button]', (e) =>
      e.preventDefault()
      e.stopPropagation()

      @appointment.practitionerLabel = $(e.currentTarget).data('practitionerLabel')
      @appointment.practitionerId = $(e.currentTarget).data('practitionerId')
      @summaryDetails.setPractitionerDetails()
      @closeModal()

  closeModal: () ->
    $('body').removeClass('no-scroll')
    $('[data-js-time-slot-releted-practitioner-list] li:not(.hidden)').addClass('hidden')
    $('[data-js-number-of-practitioners-available]').text('')
    $('[data-js-time-slot-info]').text('')
    $('[data-js-practitioners-selection-modal]').addClass('hidden')
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
    @onClosed()

  showModal: (practitionerIds) ->
    $('body').addClass('no-scroll')
    $('[data-js-number-of-practitioners-available]').text(practitionerIds.length)
    $('[data-js-time-slot-info]').text(moment(@appointment.appointmentStart).tz(@appointment.timeZoneIdentifier).format('Do MMMM, YYYY, h:mma (z)'))
    $('[data-js-practitioners-selection-modal]').removeClass('hidden')

    $.each practitionerIds, (index, practitionerId) ->
      $("[data-js-time-slot-releted-practitioner-id=#{practitionerId}]").removeClass('hidden')

    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
