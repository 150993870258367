import AES from 'crypto-js/aes'
import cryptoJsEncUtf8 from 'crypto-js/enc-utf8'

export default encryptionKey => ({
  decrypt: encryptedValue => {
    try {
      return AES.decrypt(encryptedValue, encryptionKey).toString(cryptoJsEncUtf8)
    } catch {
      return null
    }
  },
  encrypt: value => {
    if (encryptionKey) {
      return AES.encrypt(value, encryptionKey)
    } else {
      return null
    }
  },
})
