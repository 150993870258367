require('core-js/stable');

window.jQuery = require('jquery/dist/jquery');
window.$ = window.jQuery;
require('vendor/assets/javascripts/modernizr-bookings');
require('vendor/assets/javascripts/jquery-ui-1.12.1.min');
require('jquery-ujs/src/rails');
require('vendor/assets/javascripts/jquery.serialize');
require('vendor/assets/javascripts/jquery.deserialize');
require('vendor/assets/javascripts/jquery_plugins/jquery.textarea.autoresize');
window.moment = require('moment-timezone');
require('vendor/assets/javascripts/date');
require('app/assets/javascripts/bookings/app.js.coffee');
require('app/assets/javascripts/bookings/booking.js.coffee');
require('app/assets/javascripts/bookings/collapsable_categories.js.coffee');
require('app/assets/javascripts/bookings/confirmation.js.coffee');
require('app/assets/javascripts/bookings/important_notice.js');
require('app/assets/javascripts/bookings/jquery.address-1.6.min.js');
require('app/assets/javascripts/bookings/location.js.coffee');
require('app/assets/javascripts/bookings/online_bookings_form_validator.js.coffee');
require('app/assets/javascripts/bookings/patient.js.coffee');
require('app/assets/javascripts/bookings/payment.js.coffee');
require('app/assets/javascripts/bookings/practitioner.js.coffee');
require('app/assets/javascripts/bookings/practitioners-modal.js.coffee');
require('app/assets/javascripts/bookings/reservation.js.coffee');
require('app/assets/javascripts/bookings/schedule.js.coffee');
require('app/assets/javascripts/bookings/service.js.coffee');
require('app/assets/javascripts/bookings/summary.js.coffee');
require('app/assets/javascripts/bookings/summary_details.js.coffee');
require('app/assets/javascripts/bookings/svg-fallback.js');
require('app/assets/javascripts/modules/third-party/honeybadger');
require('respimage/respimage');
require('app/assets/javascripts/modules/online-bookings/cookie-consent');
require('app/assets/javascripts/modules/online-bookings/remember-me');
