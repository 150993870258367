class Bookings.Booking
  @PAGES: ['location', 'service', 'practitioner', 'schedule', 'patient', 'payment']

  constructor: (options)->
    @appointment                  = options.appointment
    @bookingReservationMinutes    = options.bookingReservationMinutes
    @rememberMeEncryptionKey      = options.rememberMeEncryptionKey
    @supportedTimeZones           = options.supportedTimeZones

    @startingPage = if @appointment.businessId? then 'service' else 'location'

    @currentPage = @startingPage

    @summaryDetails = new Bookings.SummaryDetails appointment: @appointment

    #practitionerModal
    @practitionersModal = new Bookings.PractitionersModal appointment: @appointment, summaryDetails: @summaryDetails

    @practitionersModal.onClosed = =>
      if @isSpecificPractitionerSelected()
        @schedule.finishTimeSlotSelection()
      else
        @clearAppointmentScheduleData()

    @reservation = new Bookings.Reservation appointment: @appointment, bookingReservationMinutes: @bookingReservationMinutes

    #schedule
    @schedule = new Bookings.Schedule
      allowBookingsDaysInAdvance: options.allowBookingsDaysInAdvance,
      allowTimeZoneChoice: options.allowTimeZoneChoice,
      appointment: @appointment,
      defaultTimeZone: options.defaultTimeZone,
      reservation: @reservation,
      supportedTimeZones: @supportedTimeZones
      timeZoneIdentifierForTimeZone: @timeZoneIdentifierForTimeZone

    @schedule.generateTheCalendar()

    @schedule.finishTimeSlotSelection = =>
      @schedule.setGroupAppointmentId()
      @reservation.generateAfterTimeSlotSelection()
      $.address.value 'patient'
      @validator = new Bookings.FormValidator(formId: 'form#new_booking')
      @validator.resetValidation()

    @schedule.onSelected = (practitionersIdsAvailableInTimeSlot) =>
      if not @isSpecificPractitionerSelected()
        if practitionersIdsAvailableInTimeSlot.length > 1
          @practitionersModal.showModal practitionersIdsAvailableInTimeSlot
        else
          @appointment.practitionerId = practitionersIdsAvailableInTimeSlot[0]
          @appointment.practitionerLabel = @practitioner.labelForPractitioner practitionersIdsAvailableInTimeSlot[0]
          @schedule.finishTimeSlotSelection()
      else
        @schedule.finishTimeSlotSelection()

      @summaryDetails.setScheduleDetails()

    @schedule.onBack = =>
      @schedule.resetToInitialDate()
      if @showPractitionerPage() then $.address.value('practitioner') else $.address.value('service')

    @schedule.onMonthUp = =>
      @schedule.abortCurrentGetAvailableBookingsRequest()
      @schedule.showNextMonth()
      @fetchAppointmentsForMonth()

    @schedule.onMonthDown = =>
      @schedule.abortCurrentGetAvailableBookingsRequest()
      @schedule.showPreviousMonth()
      @fetchAppointmentsForMonth()

    @schedule.onTimeZoneChange = =>
      @schedule.abortCurrentGetAvailableBookingsRequest()
      @schedule.resetToInitialDate()
      @fetchAppointmentsForMonth()

    # location
    @location = new Bookings.Location appointment: @appointment

    @location.onSelected = =>
      @clearAppointmentServiceData()
      if @showPractitionerPage() then @clearAppointmentPractitionerData()
      @clearAppointmentScheduleData()
      $.address.value 'service'
      @service.showRelevantBookingPanels()
      @location.expandSingleCategories()
      @summaryDetails.setLocationDetails()

    @payment = new Bookings.Payment onlinePaymentsEnabled: options.onlinePaymentsEnabled, stripeAccountId: options.stripeAccountId, stripePublishableKey: options.stripePublishableKey

    #service
    @service = new Bookings.Service appointment: @appointment, offerGroupAppointments: options.offerGroupAppointments, offerIndividualAppointments: options.offerIndividualAppointments, payment: @payment

    # practitioner
    @practitioner = new Bookings.Practitioner appointment: @appointment, reservation: @reservation, schedule: @schedule

    @service.onSelected = =>
      @clearAppointmentScheduleData()
      @summaryDetails.setServiceDetails()
      if @service.availablePractitioners.length == 1
        @appointment.practitionerId = @service.availablePractitioners[0].id
        @appointment.practitionerLabel = @service.availablePractitioners[0].label
        @practitioner.selectedOnPractitionersPage = @appointment.practitionerId
        @practitioner.onSelected()
      else
        @clearAppointmentPractitionerData()

      if @showPractitionerPage()
        @clearAppointmentPractitionerData()
        $.address.value('practitioner')
      else
        $.address.value('schedule')

    @service.onBack = =>
      $.address.value 'location'

    @practitioner.onSelected = =>
      @clearAppointmentScheduleData()
      @schedule.resetToInitialDate()
      $.address.value 'schedule'
      @practitioner.setPractitionerInfoOnCalendarPlaceholder()
      @summaryDetails.setPractitionerDetails()

    @practitioner.onBack = =>
      $.address.value 'service'

    # patient
    @patient = new Bookings.Patient
      rememberMeEncryptionKey: @rememberMeEncryptionKey
      supportedTimeZones: @supportedTimeZones
      timeZoneIdentifierForTimeZone: @timeZoneIdentifierForTimeZone

    @summary = new Bookings.Summary

    # kickoff the initial page
    $.address.strict false
    $.address.value @currentPage
    $.address.change (event)=>
      this.setPage event.value

    $('.button--back').on 'click', (e)=>
      $.address.value @currentPage
      this[@currentPage].onBack()

  timeZoneIdentifierForTimeZone: (timeZone) ->
    $.grep(@supportedTimeZones, (zone, i) -> zone.label == timeZone)[0]?.name

  clearAppointmentScheduleData: ->
    @appointment.appointmentStart   = null
    @appointment.possibleIds        = null
    @appointment.appointmentId      = null
    @appointment.timeZone           = null
    @appointment.timeZoneIdentifier = null

  assignBookingParamsToTheForm: ->
    $('[data-js-booking-appointment-id]').val @appointment.appointmentId
    $('[data-js-booking-appointment-start]').val @appointment.appointmentStart
    $('[data-js-booking-appointment-type-id]').val @appointment.appointmentTypeId
    $('[data-js-booking-business-id]').val @appointment.businessId
    $('[data-js-booking-practitioner-id]').val @appointment.practitionerId
    $('[data-js-privacy-policy-business-name]').text @appointment.locationName

  clearAppointmentServiceData: ->
    @appointment.typeName           = null
    @appointment.appointmentTypeId  = null

  clearAppointmentPractitionerData: ->
    @appointment.practitionerId    = null
    @appointment.practitionerLabel = null
    @practitioner.lastSelected = null

  fetchAppointmentsForMonth: ->
    @practitioner.getAppointments().then =>
      @schedule.enableOrDisableNextMonthNavigation()

  isSpecificPractitionerSelected: ()->
    @appointment.practitionerId? && !Array.isArray(@appointment.practitionerId)

  setPage: (page)->
    previousPage = @currentPage
    if page =='location'
      @currentPage = 'location'
    else if page == 'service' && @appointment.businessId?
      @currentPage = 'service'
    else if page == 'practitioner' && @appointment.businessId? && @appointment.appointmentTypeId?
      @currentPage = 'practitioner'
    else if page == 'schedule' && @appointment.businessId? && @appointment.appointmentTypeId? && @appointment.practitionerId?
      @currentPage = 'schedule'
      @schedule.resetToInitialDate()
      @practitioner.resetDataToPractitionerPageSelection()
      @fetchAppointmentsForMonth()
    else if page == 'patient' && @appointment.businessId? && @appointment.appointmentTypeId? && @appointment.appointmentStart? && @isSpecificPractitionerSelected()
      @currentPage = 'patient'
      @assignBookingParamsToTheForm()
      @patient.updateTimeZoneDetails @appointment.timeZone
    else if page == 'payment'
      @currentPage = 'payment'

    if @currentPage == @startingPage
      $('.button--back').hide()
    else
      $('.button--back').show()
      $('.notification').fadeOut(300)

    @showCurrentPage()

    if @currentPage == 'schedule' then @schedule.datepickerSquareTableCells()
    if @currentPage == previousPage then $.address.value @currentPage

  showCurrentPage: ->
    $.each Booking.PAGES, (index, page)=>
      $(".bookings-section--#{page}").toggleClass 'current', page == @currentPage
      $("#cliniko-online-bookings").scrollTop 0
    window.scrollTo(0, 0)
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
    unless @currentPage == @startingPage
      window.parent.postMessage("cliniko-bookings-page:#{@currentPage}", '*')

  showPractitionerPage: -> !@appointment.practitionerId?

  $('[data-js-open-summary-modal]').on 'click', (e) =>
    $('body').addClass('no-scroll')
    $('[data-js-summary-modal]').removeClass('hidden')
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  $('[data-js-summary-modal], [data-js-close-summary]').on 'click', (e) =>
    $('body').removeClass('no-scroll')
    $('[data-js-summary-modal]').addClass('hidden')
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  #booking cancellation
  $('[data-js-cancellation-submit]').on 'click', (e) =>
    e.stopPropagation()
    $(e.currentTarget).addClass('button--loading')
    $(e.currentTarget).prop('disabled', true)
    $(e.currentTarget).find('[data-js-cancellation-submit-button-description]').text('Cancelling appointment... please wait').after('<div class="loading__animation" role="presentation"><div class="spinner" role="presentation"><div class="mask" role="presentation"><div class="maskedCircle" role="presentation"></div></div></div></div>')
    $(e.currentTarget).parents('form#cancel_booking').submit()
