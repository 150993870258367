class Bookings.Service
  constructor: (options)->
    @appointment                 = options.appointment
    @number_of_practitioners     = 0
    @offerGroupAppointments      = options.offerGroupAppointments
    @offerIndividualAppointments = options.offerIndividualAppointments
    @payment                     = options.payment

    if @offerIndividualAppointments and @offerGroupAppointments
      $('[role=tab]').keydown (e) ->
        if e.which is 13 or e.which is 32
          e.preventDefault()
          $(this).click()

      $('.bookings-section--service').on 'click', '[role=tab]', (e) =>
        e.preventDefault()
        e.stopPropagation()
        @lastClickedTab = e.currentTarget
        @selectTab e.currentTarget

    @showRelevantBookingPanels()

    $('.bookings-section--service').on 'click', ".module .button--select", (e)=>
      e.preventDefault()
      e.stopPropagation()
      selectedService = $(e.currentTarget).parents '.module__item'

      @setAppointmentTypeId selectedService
      @appointment.typeName = selectedService.data 'appointmentTypeName'
      @appointment.selectedService = selectedService

      @showAvailablePractitionersWithSelectedServices()
      @onSelected()

    $('.bookings-section--service').on "mousedown keypress", ".button--reveal-description", (e)=>
      $(e.currentTarget).parents().children('.module__description').toggle()
      $(e.currentTarget).closest('.button--reveal-description').toggleClass 'active'
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

    $('.bookings-section--service').on "mouseup", ".button--reveal-description", (e)=>
      $(e.currentTarget).closest('.button--reveal-description').blur()

  showBookingsPanel: (panel) ->
    $(panel).show().attr 'aria-hidden', false
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  showRelevantBookingPanels: ->
    $('#group').hide().attr 'aria-hidden', true
    $('#individual').hide().attr 'aria-hidden', true

    selectedLocationOffersIndividualAppointments = $('#individual').find('[data-appointment-type-id]').not('.hidden').length > 0
    selectedLocationOffersGroupAppointments = $('#group').find('[data-appointment-type-id]').not('.hidden').length > 0

    if selectedLocationOffersIndividualAppointments and selectedLocationOffersGroupAppointments
      $('[role=tablist]').show()
      @selectTab @lastClickedTab || $('[role=tab]')[0]
    else
      $('[role=tablist]').hide()
      if selectedLocationOffersIndividualAppointments
        @showBookingsPanel('#individual')
      else if selectedLocationOffersGroupAppointments
        @showBookingsPanel('#group')

  setAppointmentTypeId: (serviceDataObject) ->
    @appointment.appointmentTypeId = serviceDataObject.attr('data-appointment-type-id')
    @payment.onAppointmentTypeIdChange(@appointment.appointmentTypeId)

  removeAppointmentTypeId: ->
    @appointment.appointmentTypeId = null

  onSelected: ->
  onBack: ->

  reset: ->
    $('.bookings-section__list--service__item').each (index, site)=>
      $(site).toggleClass 'hidden', $(site).data('location') == @appointment.businessId
    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  selectTab: (tabElement) ->
    $('[role=tab]').removeClass('tabs__tab--current').attr 'aria-selected', false
    $('[role=tabpanel]').hide().attr 'aria-hidden', true
    $tabElement = $(tabElement)
    $tabElement.addClass('tabs__tab--current').attr 'aria-selected', true
    $tabElement.blur()
    @showBookingsPanel '#' + $tabElement.attr('aria-controls')

  showAvailablePractitionersWithSelectedServices: ->
    @availablePractitioners = []

    $(".bookings-section--practitioner .module__item:not([data-js-any-practitioner])").each (index, element) =>
      practitioner = $(element)
      practitionerAppointmentTypes = practitioner.data('appointmentTypeIds')
      practitionerBusinessIds = practitioner.data('practitionerBusinessIds')
      practitionerId =  practitioner.data('practitionerId')
      practitionerLabel =  practitioner.data('practitionerLabel')
      hasService = $.inArray(@appointment.appointmentTypeId, practitionerAppointmentTypes) != -1
      inBusiness = $.inArray(@appointment.businessId, practitionerBusinessIds) != -1

      if inBusiness and hasService
        practitioner.show()
        @availablePractitioners.push({ id: practitionerId, label: practitionerLabel })
      else
        practitioner.hide()

    if @availablePractitioners.length > 1
      $(".bookings-section--practitioner .module__item[data-js-any-practitioner]").show()
    else
      $(".bookings-section--practitioner .module__item[data-js-any-practitioner]").hide()
