import { showBookingError } from './booking_error'

class Bookings.Payment
  constructor: (options)->
    @appointmentCostPreview = {}
    @onlinePaymentsEnabled = options.onlinePaymentsEnabled
    @stripeAccountId = options.stripeAccountId
    @stripePublishableKey = options.stripePublishableKey
    @validator = new Bookings.FormValidator(formId: '[data-js-payment-form]')

    if @onlinePaymentsEnabled
      try
        @initStripe()
      catch e
        showBookingError("Uh oh! There was a problem setting up. Please contact us to get your booking scheduled.")
        throw e

      $('[data-js-online-payment-use-home-address-checkbox]').on 'change', (e) =>
        if e.currentTarget.checked
          $('[data-js-booking-billing-address]').hide()
          $('[data-js-online-payments-billing-address-line1]').val($('[data-js-patient-input-address-1]').val()).change()
          $('[data-js-online-payments-billing-address-line2]').val($('[data-js-patient-input-address-2]').val()).change()
          $('[data-js-online-payments-billing-address-city]').val($('[data-js-patient-input-city]').val()).change()
          $('[data-js-online-payments-billing-address-state]').val($('[data-js-patient-input-state]').val()).change()
          $('[data-js-online-payments-billing-address-zip]').val($('[data-js-patient-input-post-code]').val()).change()
          $('[data-js-online-payments-billing-address-country]').val($('[data-js-patient-input-country-code]').val()).change()
        else
          $('[data-js-booking-billing-address]').show()
          $('[data-js-online-payments-billing-address-line1]').val('')
          $('[data-js-online-payments-billing-address-line2]').val('')
          $('[data-js-online-payments-billing-address-city]').val('')
          $('[data-js-online-payments-billing-address-state]').val('')
          $('[data-js-online-payments-billing-address-zip]').val('')
          $('[data-js-online-payments-billing-address-country]').val('')

      $('[data-js-privacy-policy-in-payment-form-checkbox]').on 'change', (e) =>
        $('[data-js-privacy-policy-in-patient-form-checkbox]').prop('checked', e.currentTarget.checked)

      $('[data-js-payment-submit-button]').on 'click', (e) =>
        e.preventDefault()
        e.stopPropagation()

        if $('[data-js-booking-submit-pay-now-button]').length
          @submitWithPayment(e)
        else
          @submitWithoutPayment(e)

      $('[data-js-online-payments-required-radio-input]').on 'change', (e) =>
        value = $('[data-js-online-payments-required-radio-input]:checked').val()
        @setOptionalPayment(value)

  confirmPaymentIntentAndSubmit: ->
    data = {
      payment_method: {
        card: @cardNumber,
        billing_details: {
          address: {
            city: $('[data-js-online-payments-billing-address-city]').val(),
            country: $('[data-js-online-payments-billing-address-country]').val(),
            line1: $('[data-js-online-payments-billing-address-line1]').val(),
            line2: $('[data-js-online-payments-billing-address-line2]').val(),
            postal_code: $('[data-js-online-payments-billing-address-zip]').val(),
            state: $('[data-js-online-payments-billing-address-state]').val()
          },
          email: $('[data-js-patient-input-patient-email]').val(),
          name: $('[data-js-online-payments-card-name]').val(),
          phone: $('[data-js-patient-input-patient-mobile-number]').val()
        }
      }
    }

    # Will handle any SCA validation needed
    @stripe.confirmCardPayment(@stripePaymentIntent.client_secret, data).then (result) =>
      if result.error
        error = result.error
        errorElement = switch error.param
          when 'cvc' then $('[data-js-online-payment-cvc-error]')
          when 'exp_month' then $('[data-js-online-payment-expiry-error]')
          else $('[data-js-online-payment-card-error]')

        @validator.markAsInvalid errorElement
        @validator.addErrorMessage errorElement, error.message
        $('html, body').animate({ scrollTop: $('.error').offset().top }, 300)
        window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
        @hideLoader()
      else
        window.removeLeavingWarning()
        $('[data-js-new-booking-form]').submit()

  handlePaymentAndForm: ->
    # We don't actually hit Stripe if payment isn't required and the patient didn't enter payment information
    # OR if the appointment type has no cost associated
    if (!@appointmentCostPreview.required && @cardNumber._empty) || parseFloat(@appointmentCostPreview.net_amount) <= 0
      window.removeLeavingWarning()
      $('[data-js-new-booking-form]').submit()
    else if @cardNumber._empty || @cardCvc._empty || @cardExpiry._empty
      [@cardNumber, @cardCvc, @cardExpiry].forEach (stripeElement) =>
        if stripeElement._empty
          @validator.markAsInvalid stripeElement._parent
          @validator.addErrorMessage stripeElement._parent, 'This field is required'

      $('html, body').animate({ scrollTop: $('.error').offset().top }, 300)
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
      @hideLoader()
    else
      @runPaymentSetup()
      .done (data) =>
        @stripePaymentIntent = data.stripe_payment_intent
        $('[data-js-booking-stripe-payment-id]').val(@stripePaymentIntent.id)
        $('[data-js-booking-reservation-key]').val(data.reservation_key)
        @confirmPaymentIntentAndSubmit()
      .fail (data) =>
        if data.status >= 400 && data.status < 500
          message =
            if data.responseJSON?
              data.responseJSON.error
            else
              data.responseText
          showBookingError message
        else
          showBookingError 'Unable to process payment, you have not been charged, please try again'
        @hideLoader()

  hideLoader: ->
    button = $('[data-js-payment-submit-button]')
    button.removeClass('button--loading')
    button.prop('disabled', false)
    button.find('.button__value').text('Pay now')
    button.find('.loading__animation').remove()

  hidePaymentInputs: ->
    $('[data-js-online-payments-fields]').hide()
    $('[data-js-booking-submit-button-text]').text 'Book appointment'
    $('[data-js-patient-next-action-button-text]').text 'Complete booking'
    $('[data-js-patient-next-action-button]').attr('data-js-patient-form-button-go-to-payment', '')
    $('[data-js-privacy-policy-in-patient-form]').show()
    $('[data-js-privacy-policy-in-patient-form-checkbox]').attr('required', '')
    $('[data-js-privacy-policy-in-patient-form-checkbox]').attr('aria-required', '')
    @setOnlinePaymentsInputsNotRequired()

  initStripe: ->
    @stripe = Stripe(@stripePublishableKey, {
      stripeAccount: @stripeAccountId
    })
    elements = @stripe.elements()
    style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    @cardNumber = elements.create('cardNumber', {showIcon: true, style: style})
    cardNumberElement = $('[data-js-online-payment-card-number-div]')
    @cardNumber.mount('[data-js-online-payment-card-number-div]')
    @cardNumber.on 'change', (event) =>
      @onCardElementChange(event, cardNumberElement)

    @cardExpiry = elements.create('cardExpiry', {style: style})
    cardExpiryElement = $('[data-js-online-payment-expiry-date-div]')
    @cardExpiry.mount('[data-js-online-payment-expiry-date-div]')
    @cardExpiry.on 'change', (event) =>
      @onCardElementChange(event, cardExpiryElement)

    @cardCvc = elements.create('cardCvc', {placeholder: '', style: style})
    cardCvcElement = $('[data-js-online-payment-cvv-div]')
    @cardCvc.mount('[data-js-online-payment-cvv-div]')
    @cardCvc.on 'change', (event) =>
      @onCardElementChange(event, cardCvcElement)

    @cardNumber.on 'blur', (event) =>
      if !@appointmentCostPreview.required
        if @cardNumber._empty
          @setOnlinePaymentsInputsNotRequired()
        else
          @setOnlinePaymentsInputsRequired()

  invalidStripeElementsExist: ->
    @cardNumber._invalid || @cardExpiry._invalid || @cardCvc._invalid

  onAppointmentTypeIdChange: (appointmentTypeId) ->
    if @onlinePaymentsEnabled && appointmentTypeId != @appointmentTypeId
      @appointmentTypeId = appointmentTypeId
      $.ajax "/bookings/payments/appointment_cost_preview",
        type: 'GET',
        data: {
          appointment_type_id: appointmentTypeId
        },
        dataType: 'json'
        success: (data) =>
          @appointmentCostPreview = data
          $('[data-js-online-payments-price]').text(@appointmentCostPreview.net_amount_formatted)
          if parseFloat(@appointmentCostPreview.net_amount) > 0.0
            @showPaymentInputs()
            @setOptionalPayment('now')
            if @appointmentCostPreview.required
              $('[data-js-online-payments-required-radio-fieldset]').hide()
            else
              $('[data-js-online-payments-required-radio-fieldset]').show()

            if @appointmentCostPreview.online_payments_mode == 'deposit_required'
              $('[data-js-online-payments-title]').text 'Deposit required'
            else
              $('[data-js-online-payments-title]').text 'Total to pay'
          else
            @hidePaymentInputs()

  onBack: -> $.address.value 'patient'

  onCardElementChange: (event, element) ->
    if event.error
      @validator.markAsInvalid element
      @validator.addErrorMessage element, event.error.message
    else
      @validator.markAsValid element
      @validator.removeErrorMessage element

  runPaymentSetup: ->
    $.ajax '/bookings/payments/setup',
      type: 'POST',
      data: $('[data-js-new-booking-form]').serialize(),
      data_type: 'json'

  showLoader: (e) ->
    $(e.currentTarget).addClass('button--loading')
    $(e.currentTarget).prop('disabled', true)
    $(e.currentTarget).find('.button__value').text('Booking… please wait').after('<div class="loading__animation" role="presentation"><div class="spinner" role="presentation"><div class="mask" role="presentation"><div class="maskedCircle" role="presentation"></div></div></div></div>')

  showPaymentInputs: ->
    $('[data-js-online-payments-fields]').show()
    $('[data-js-card-and-billing-fields]').show()
    $('[data-js-booking-submit-button-text]').text 'Book & pay'
    $('[data-js-patient-next-action-button-text]').text 'Continue'
    $('[data-js-patient-next-action-button]').attr('data-js-patient-form-button-go-to-payment', 'true')
    $('[data-js-privacy-policy-in-patient-form]').hide()
    $('[data-js-privacy-policy-in-patient-form-checkbox]').removeAttr('required')
    $('[data-js-privacy-policy-in-patient-form-checkbox]').removeAttr('aria-required')

    if @appointmentCostPreview.required
      @setOnlinePaymentsInputsRequired()

  setOnlinePaymentsInputsRequired: ->
    $('[data-js-required-online-payments-input]').each (i, el) =>
      $(el).attr('required', '')
      $(el).attr('aria-required', '')
      @validator.markAsValid $(el)
      @validator.removeErrorMessage $(el)

  setOnlinePaymentsInputsNotRequired: ->
    $('[data-js-required-online-payments-input]').each (i, el) =>
      $(el).removeAttr('required')
      $(el).removeAttr('aria-required')
      @validator.markAsValid $(el)
      @validator.removeErrorMessage $(el)

  setOptionalPayment: (value) ->
    submitButton = $('[data-js-payment-submit-button]')
    submitButtonText = $('[data-js-booking-submit-button-text]')
    cardBillingInputs = $('[data-js-card-and-billing-fields]')
    if value == 'later'
      $('[data-js-pay-later-radio-input]').prop("checked", true)
      cardBillingInputs.hide(300)
      submitButtonText.text('Book appointment')
      submitButton.removeAttr('data-js-booking-submit-pay-now-button')
      submitButton.attr('data-js-booking-submit-without-payment-button', '')
      @setOnlinePaymentsInputsNotRequired()
    else
      $('[data-js-pay-now-radio-input]').prop("checked", true)
      cardBillingInputs.show(300)
      submitButtonText.text('Book & pay')
      submitButton.removeAttr('data-js-booking-submit-without-payment-button')
      submitButton.attr('data-js-booking-submit-pay-now-button', '')
      @setOnlinePaymentsInputsRequired()

  submitWithPayment: (e) ->
    if !@invalidStripeElementsExist() && @validator.validateForm()
      @showLoader(e)
      @handlePaymentAndForm()
    else
      [@cardNumber, @cardCvc, @cardExpiry].forEach (stripeElement) =>
        if stripeElement._empty
          @validator.markAsInvalid stripeElement._parent
          @validator.addErrorMessage stripeElement._parent, 'This field is required'
      $('html, body').animate({ scrollTop: $('.error').offset().top }, 300)
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  submitWithoutPayment: (e) ->
    @showLoader(e)
    window.removeLeavingWarning()
    $('[data-js-booking-stripe-payment-id]').val('')
    $('[data-js-new-booking-form]').submit()
