class Bookings.Summary
  constructor: (options)->
    $('[data-js-booking-submit-button]').on 'click', (e) =>
      window.removeLeavingWarning()
      e.preventDefault()
      e.stopPropagation()
      @showLoader(e)
      $('[data-js-new-booking-form]').submit()

  showLoader: (e) ->
    $(e.currentTarget).addClass('button--loading')
    $(e.currentTarget).prop('disabled', true)
    $(e.currentTarget).find('.button__value').text('Booking… please wait').after('<div class="loading__animation" role="presentation"><div class="spinner" role="presentation"><div class="mask" role="presentation"><div class="maskedCircle" role="presentation"></div></div></div></div>')

  onBack: -> $.address.value 'patient'
