class Bookings.Practitioner
  constructor: (options)->
    @appointment                 = options.appointment
    @reservation                 = options.reservation
    @schedule                    = options.schedule
    @selectedOnPractitionersPage = options.appointment.practitionerId

    @practitionerInfoOnCalendarPlaceholder = $('.bookings-section--schedule .section__heading').find 'span'

    $('.bookings-section--practitioner').on 'click', '[data-js-individual-practitioner-select]', (e)=>
      e.preventDefault()
      e.stopPropagation()
      selectedPractitioner = $(e.currentTarget).parents '.module__item'
      @assignPractitionerDataToAppointment selectedPractitioner

      @selectedOnPractitionersPage = @appointment.practitionerId
      @onSelected()

    $('.bookings-section--practitioner').on 'click', '[data-js-all-practitioners-select]', (e)=>
      e.preventDefault()
      e.stopPropagation()
      @appointment.practitionerId = $(e.currentTarget).parents('.module__item').data('practitionerId')
      @appointment.practitionerLabel = ''

      @selectedOnPractitionersPage = @appointment.practitionerId
      @onSelected()

    $('.bookings-section--practitioner').on "mousedown keypress", ".button--reveal-description", (e)=>
      $(e.currentTarget).parents().children('.module__description').toggle()
      $(e.currentTarget).closest('.button--reveal-description').toggleClass 'active'
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

    $('.bookings-section--practitioner').on "mouseup", ".button--reveal-description", (e)=>
      $(e.currentTarget).closest('.button--reveal-description').blur()

  onSelected: ->
  onBack: ->

  resetDataToPractitionerPageSelection: ->
    if Array.isArray(@selectedOnPractitionersPage) then @appointment.practitionerLabel = ''
    @appointment.practitionerId = @selectedOnPractitionersPage


  #BP it can be refactor later as Kelly do a design for it to handle all scenarios
  assignPractitionerDataToAppointment: (practitionerDataObject)->
    @appointment.practitionerLabel = practitionerDataObject.data('practitionerLabel')
    @appointment.practitionerId = @fetchPractitionerId practitionerDataObject

  fetchPractitionerId: (practitionerDataObject)->
    practitionerDataObject.attr('data-practitioner-id')

  labelForPractitioner: (practitionerId) ->
    $(".bookings-section--practitioner [data-practitioner-id=#{practitionerId}]").data('practitionerLabel')

  setPractitionerInfoOnCalendarPlaceholder: ->
    info = ''
    info = "with #{@appointment.practitionerLabel}" if @appointment.practitionerLabel
    @practitionerInfoOnCalendarPlaceholder.text info

  getAppointments: ->
    @schedule.disableTimeZoneSelector()
    backDisabledState = $('.button--datepicker-nav.back').prop('disabled')
    facebookPageId = null

    $('.datepicker__loading').show()
    $('.button--datepicker-nav').prop('disabled', true)

    if $('#facebook_page_id').length == 1
      facebookPageId = $('#facebook_page_id').val()

    $.ajax '/bookings/days',
      type: 'GET'
      data:
        appointment_type_id: @appointment.appointmentTypeId
        business_id: @appointment.businessId
        facebook_page_id: facebookPageId
        month: @schedule.displayedMonth() + 1
        practitioner_ids: @appointment.practitionerId
        reservation_key: @reservation.reservationKey
        time_zone: @schedule.timeZone()
        year: @schedule.displayedYear()
      dataType: 'json'
      success: (data) =>
        @schedule.enableDaysWithAvailability data
        $('.datepicker__loading').hide()
        $('.button--datepicker-nav.back').prop('disabled', backDisabledState)
        $('.button--datepicker-nav.forward').prop('disabled', false)
        window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
        @schedule.enableTimeZoneSelector()
