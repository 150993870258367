class Bookings.Patient
  constructor: (options)->
    @supportedTimeZones = options.supportedTimeZones
    @timeZoneIdentifierForTimeZone = options.timeZoneIdentifierForTimeZone
    @validator = new Bookings.FormValidator(formId: 'form#new_booking')
    $('.bookings-section--patient .button--select').prop('disabled', false)
    @rememberMe = rememberMeInitializer.call {}, options.rememberMeEncryptionKey
    @rememberMe.fillForm()

    $('[data-js-patient-time-zone]').on 'change', (e) =>
      @preferredTimeZone = $(e.currentTarget).val()
      if @preferredTimeZone
        @showBookingTimeNoteUnderTimeZoneSelector @preferredTimeZone
      else
        @hideBookingTimeNoteFromUnderTimeZoneSelector()

    $('.bookings-section--patient').on 'click', '.button--select', (e) =>
      e.preventDefault()
      e.stopPropagation()
      if @validator.validateForm()
        if $('#booking_remember_me').prop('checked')
          @rememberMe.save()
        else
          @rememberMe.delete()

        @onSelected(e)

    $('.patient__form textarea').autoResize()

  updateTimeZoneDetails: (timeZone) ->
    selectedTimeZone = @preferredTimeZone || timeZone
    if selectedTimeZone != timeZone
      @showBookingTimeNoteUnderTimeZoneSelector selectedTimeZone
    else
      @hideBookingTimeNoteFromUnderTimeZoneSelector()
      $('[data-js-patient-time-zone]').val timeZone

  showBookingTimeNoteUnderTimeZoneSelector: (timeZone) ->
    bookingTimeUtc = $('[data-js-booking-appointment-start]').val()
    timeZoneIdentifier = @timeZoneIdentifierForTimeZone timeZone
    bookingTimeInTimeZone = moment(bookingTimeUtc).tz(timeZoneIdentifier).format 'Do MMMM, YYYY h:mma (z)'

    $('[data-js-patient-time-zone-change-warning--booking-time]').text bookingTimeInTimeZone
    $('[data-js-patient-time-zone-change-warning]').closest('.form__item').addClass 'warning'
    $('[data-js-patient-time-zone-change-warning]').removeClass 'hidden'
    $('[data-js-patient-time-zone-change-warning]').attr 'aria-hidden', false

  hideBookingTimeNoteFromUnderTimeZoneSelector: ->
    $('[data-js-patient-time-zone-change-warning--booking-time]').text ''
    $('[data-js-patient-time-zone-change-warning]').closest('.form__item').removeClass 'warning'
    $('[data-js-patient-time-zone-change-warning]').addClass 'hidden'
    $('[data-js-patient-time-zone-change-warning]').attr 'aria-hidden', true

  onBack: -> $.address.value 'schedule'

  onSelected: (e) ->
    if $(e.currentTarget).attr('data-js-patient-form-button-go-to-payment')
      $.address.value 'payment'
    else
      window.removeLeavingWarning()
      @showLoader(e)
      $('[data-js-new-booking-form]').submit()

  showLoader: (e) ->
    $(e.currentTarget).addClass('button--loading')
    $(e.currentTarget).prop('disabled', true)
    $(e.currentTarget).find('.button__value').text('Booking… please wait').after('<div class="loading__animation" role="presentation"><div class="spinner" role="presentation"><div class="mask" role="presentation"><div class="maskedCircle" role="presentation"></div></div></div></div>')
