isSupported = ->
  testKey = 'test'
  try
    storage = window['localStorage']
    storage.setItem testKey, '1'
    storage.removeItem testKey
    return true
  catch error
    return false

supported = isSupported()

localStorageSupport = { supported, isSupported }
export default localStorageSupport
