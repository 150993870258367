export function hideBookingError() {
  $('[data-js-online-booking-error-div]').hide();
  $('[data-js-online-booking-error-p]').text('');
  return $('.breadcrumb').hide().show(0);
}

export function showBookingError(message) {
  $('[data-js-online-booking-error-p]').text(message);
  $('[data-js-online-booking-error-div]').show();
  $('html, body').animate({ scrollTop: $('[data-js-online-booking-error-div]').position().top }, 300);
  return window.parent.postMessage('cliniko-bookings-resize:' + document.body.offsetHeight, '*');
}
