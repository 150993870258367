class Bookings.Confirmation
  isInAppWebView = ->
    rules = [
      'WebView',
      '(iPhone|iPod|iPad)(?!.*Safari\/)',
      'Android.*(wv)',
      '\bFB\w+\/',
      '\bTwitter',
      '\bInstagram'

    ]
    regex = new RegExp rules.join('|'), 'ig'
    !!navigator.userAgent.match regex

  constructor: (options)->
    $('[data-js-ical-link]').remove() if isInAppWebView()
    @setGoogleCalendarLink()

    $(".button--reveal-description").on "keypress mousedown", (e)->
      $(e.currentTarget).parents().children('.module__description').toggle()
      $(e.currentTarget).closest('.button--reveal-description').toggleClass 'active'

    $('.button--reveal-description').on "mouseup", (e)=>
      $(e.currentTarget).closest('.button--reveal-description').blur()

    $('.button--back').hide()

    window.parent.postMessage('cliniko-bookings-page:confirmed', '*')

  setGoogleCalendarLink: ->
    appointmentType            = $('[data-js-appointment-type]').text()
    appointmentTypeDescription = $('[data-js-appointment-type-description]').data('jsAppointmentTypeDescription')
    endTime   = $('[data-js-appointment-time]').data 'startTime'
    startTime = $('[data-js-appointment-time]').data 'endTime'
    location  = $('[data-js-business-location]').data('jsBusinessLocation')

    link = "https://www.google.com/calendar/render?action=TEMPLATE&text=#{appointmentType}&dates=#{endTime}/#{startTime}&details=#{appointmentTypeDescription}&location=#{location}&sf=true&output=xml"
    $('[data-js-google-calendar-link]').attr 'href', link

  locationAddress: ->
    street = $('[data-js-location-address]').data 'jsLocationAddress'
    country = $('.bookings-section--confirmation .module__address').find('.country').text()
    "#{street} #{country}"
