import Honeybadger from '@honeybadger-io/js'

Honeybadger.configure
  apiKey: window.HONEYBADGER_API_KEY
  enableUncaught: false
  environment: window.environment
  revision: window.version

Honeybadger.setContext
  type: 'javascript'
  account_id: window.account_id
  user_id: window.user_id
  shard: window.CLINIKO_SHARD

Honeybadger.removeContext = (keys) ->
  Honeybadger.setContext _.omit(Honeybadger.context, keys)

# Webpack javascript can include honeybadger for manual error reporting via import or require
# Non-webpack javascript may expect `window.Honeybadger` to be available for reporting, so we're adding the library to window
window.Honeybadger = Honeybadger

export default Honeybadger
