class Bookings.SummaryDetails
  constructor: (options)->
    @appointment = options.appointment
    $('[data-js-summary-location-name]').text @appointment.locationName || 'Select a location to start booking.'

    if $('[data-js-summary-practitioner]').data('jsSummaryPractitionerPreselected')
      $('[data-js-summary-pratitioner-section]').removeClass('hidden')

  formatPatientDob: ->
    day = $('[data-js-patient-input-date-of-birth].day').val()
    month = $('[data-js-patient-input-date-of-birth].month').val()
    year = $('[data-js-patient-input-date-of-birth].year').val()

    "#{day}#{@ordinalSuffixInDate day} #{Bookings.Schedule.MONTHLABELS[month - 1]}, #{year}"

  ordinalSuffixInDate: (dayNumber) ->
    value = parseInt dayNumber, 10
    if value > 10 && value < 14 then return 'th'
    switch value % 10
      when 1 then 'st'
      when 2 then 'nd'
      when 3 then 'rd'
      else 'th'

  setLocationDetails: ->
    $('[data-js-summary-location-name]').text @appointment.locationName
    $('[data-js-summary-location-address]').text @appointment.locationAddress || ''
    $('[data-js-summary-service]').text ''
    $('[data-js-summary-price]').text ''
    $('[data-js-summary-service-section]').addClass('hidden')
    $('[data-js-summary-change-link]').removeClass('hidden')

  setPractitionerDetails: ->
    if @appointment.practitionerLabel
      $('[data-js-summary-practitioner]').text @appointment.practitionerLabel
      $('[data-js-summary-pratitioner-section]').removeClass('hidden')
    else
      $('[data-js-summary-practitioner]').text ''
      $('[data-js-summary-pratitioner-section]').addClass('hidden')

  setScheduleDetails: ->
    $('[data-js-summary-date]').text moment(@appointment.appointmentStart).tz(@appointment.timeZoneIdentifier).format('Do MMMM, YYYY')
    $('[data-js-summary-time]').text moment(@appointment.appointmentStart).tz(@appointment.timeZoneIdentifier).format('h:mma (z)')
    $('[data-js-summary-schedule-section]').removeClass('hidden')
    @setPractitionerDetails()

  setServiceDetails: ->
    $('[data-js-summary-service]').text @appointment.typeName
    if @appointment.selectedService.data 'showPrices'
      $('[data-js-summary-price]').text @appointment.selectedService.data('price')
    else
      $('[data-js-summary-price]').text ''

    $('[data-js-summary-service-section]').removeClass('hidden')

  $('[data-js-summary-cancel] .summary__section').removeClass('hidden')
