export default function () {
  var _onload = function(){
   if (document.readyState && !/loaded|complete/.test(document.readyState)){setTimeout(_onload, 10);return}
   if (!window.__adroll_loaded){window.__adroll_loaded=true;setTimeout(_onload, 50);return}
   var scr = document.createElement("script");
   var host = (("https:" == document.location.protocol) ? "https://s.adroll.com" : "http://a.adroll.com");
   scr.setAttribute('async', 'true');
   scr.type = "text/javascript";
   scr.src = host + "/j/roundtrip.js";
   ((document.getElementsByTagName('head') || [null])[0] ||
    document.getElementsByTagName('script')[0].parentNode).appendChild(scr);
  };
  _onload();
};
