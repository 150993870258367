import encryption from 'front-end/modules/encryption'
import localStorageSupport from 'app/assets/javascripts/modules/utilities/local-storage-support'

window.rememberMeInitializer = (encryptionKey) ->
  { decrypt, encrypt } = encryption encryptionKey
  formSelector = '[data-js-remember-me-form]'
  localStorageKey = 'encrypted-remember-me'

  @delete = ->
    if localStorageSupport.supported
      localStorage.removeItem localStorageKey

  @fillForm = ->
    return unless localStorageSupport.supported
    if localStorage.hasOwnProperty localStorageKey
      serializedFormData = decrypt localStorage.getItem(localStorageKey)
      $(formSelector).deserialize serializedFormData if serializedFormData

  @save = ->
    serializedFormData = $("#{formSelector} [data-js-remember-me-field]").serialize()
    localStorage.setItem localStorageKey, encrypt(serializedFormData)

  return @
