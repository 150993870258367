window.Bookings = {}
window.bookingsStart = (businessId, locationName, locationAddress, locationTimeZone, practitionerId, offerGroupAppointments, offerIndividualAppointments, allowBookingsDaysInAdvance, bookingReservationMinutes, rememberMeEncryptionKey, onlinePaymentsEnabled, stripeAccountId, stripePublishableKey, supportedTimeZones, defaultTimeZone, allowTimeZoneChoice) ->
  appointment = {businessId: businessId, locationName: locationName, locationAddress: locationAddress, locationTimeZone: locationTimeZone, appointmentTypeId: null, practitionerId: practitionerId }
  new Bookings.Booking
    allowBookingsDaysInAdvance: allowBookingsDaysInAdvance
    allowTimeZoneChoice: allowTimeZoneChoice
    appointment: appointment
    bookingReservationMinutes: bookingReservationMinutes
    offerGroupAppointments: offerGroupAppointments
    offerIndividualAppointments: offerIndividualAppointments
    onlinePaymentsEnabled: onlinePaymentsEnabled
    rememberMeEncryptionKey: rememberMeEncryptionKey
    stripeAccountId: stripeAccountId
    stripePublishableKey: stripePublishableKey
    supportedTimeZones: supportedTimeZones
    defaultTimeZone: defaultTimeZone

window.bookingsConfirmation = ->
  new Bookings.Confirmation()

$('[required="required"]').each ->
  id = $(this).attr('id')
  $("label[for='#{id}']").addClass('required')

onBeforeUnload = (event) ->
  if window.location.hash == '#summary'
    event.returnValue = ''
    event.preventDefault()

window.removeLeavingWarning = -> window.removeEventListener 'beforeunload', onBeforeUnload

window.addEventListener 'beforeunload', onBeforeUnload
