class Bookings.FormValidator
  constructor: (options)->
    @form = $("#{options.formId}")
    @emailValidationRegex = /^[A-Z0-9!#$%&'\*+-\/=?^_`{\|}~]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i
    @validations = {
      email: "Please enter a valid email address.",
      integer: "Please enter a valid number.",
      maximumlength: "The value of this field is too long",
      policy: "Acceptance is required.",
      required: "This field is required.",
      dob: "Please select a valid date"
    }

    $(@form).on 'focus', 'input:not(:hidden)', (e)=>
      if @dobModified()
        @validateField $('select')

    $(@form).on 'change keyup', '.error select', (e)=>
      @markAsValid e.currentTarget
      @removeErrorMessage e.currentTarget

    $(@form).on 'blur', 'input:not(:hidden)', (e)=>
      @markAsValid e.currentTarget
      @removeErrorMessage e.currentTarget
      @validateField e.currentTarget

    $(@form).on 'change keyup', '.error input:not(:hidden)', (e)=>
      @markAsValid e.currentTarget
      @removeErrorMessage e.currentTarget
      @validateField e.currentTarget

  validateField: (field)->
    @markAsValid(field)
    @removeErrorMessage(field)
    for validatorName, validationMessage of @validations
      if ($(field).prop("#{validatorName}") || $(field).attr("#{validatorName}")) && !this["#{validatorName}"](field)
        @markAsInvalid field
        @addErrorMessage field, @validations["#{validatorName}"]
        return false
    return true

  validateForm: ->
    formValid = true
    for field in $(@form).find('input, select').not(':hidden')
      if !@validateField(field) then formValid = false

    if !formValid
      $('html, body').animate({ scrollTop: $('.error').offset().top }, 300)
      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
    formValid

  resetValidation: ->
    for field in $(@form).find('input, select').not(':hidden')
      @markAsValid(field)
      @removeErrorMessage(field)

    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  prepareDayOfBirth: ->
    year = $('#booking_date_of_birth_1i').val()
    month = $('#booking_date_of_birth_2i').val()
    day = $('#booking_date_of_birth_3i').val()
    "#{year}/#{month}/#{day}"

  email: (field)->
    @emailValidationRegex.test $(field).val()

  integer: (field)->
    !isNaN parseInt($(field).val(), 10)

  dob: ->
    @allDobFieldsSet() &&
    Date.parse(@prepareDayOfBirth()) != null

  dobModified: ->
    !isNaN(parseInt($('#booking_date_of_birth_1i').val(), 10)) ||
    !isNaN(parseInt($('#booking_date_of_birth_2i').val(), 10)) ||
    !isNaN(parseInt($('#booking_date_of_birth_3i').val(), 10))

  allDobFieldsSet: ->
    !isNaN(parseInt($('#booking_date_of_birth_1i').val(), 10)) &&
    !isNaN(parseInt($('#booking_date_of_birth_2i').val(), 10)) &&
    !isNaN(parseInt($('#booking_date_of_birth_3i').val(), 10))

  maximumlength: (field)->
    $(field).val().length <= parseInt $(field).attr('maximumlength'), 10

  required: (field)=>
    if @isCheckableElement(field) then @isChecked(field) else !@isBlank(field)

  policy: (field)->
    @required(field)

  isBlank: (field)->
    $(field).val().trim() == ''

  isChecked: (field)->
    $(field).is(':checked')

  isCheckableElement: (field)->
    /radio|checkbox/i.test field.type

  addErrorMessage: (field, message)->
    $(field).closest('.form__item').find('p.form__item__error-message').text(message).removeClass 'hidden'

  markAsInvalid: (field)->
    $(field).closest('.form__item').addClass 'error'

  markAsValid: (field)->
    $(field).closest('.form__item').removeClass 'error'

  removeErrorMessage: (field)->
    $(field).closest('.form__item').find('p.form__item__error-message').addClass('hidden').text ''
