class Bookings.Location
  constructor: (options)->
    @appointment = options.appointment

    $('.bookings-section--location').on 'click', '.module__item .button--select', (e)=>
      e.preventDefault()
      e.stopPropagation()
      @clearServices()

      selectedLocation = $(e.currentTarget).parents '.module__item'

      @showServicesOfferedInSelectedLocation selectedLocation
      @hideEmptyCategories()

      @appointment.businessId       = selectedLocation.attr 'data-location-id'
      @appointment.locationName     = selectedLocation.data 'locationName'
      @appointment.locationAddress  = selectedLocation.data 'locationAddress'
      @appointment.locationTimeZone = selectedLocation.data 'locationTimeZone'

      @onSelected()
      $('.bookings-section--service .bookings-section__heading').focus()
      $('.bookings-section--location').parents('.bookings__content').find('footer .button--back').show()

      window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  onSelected: ->
  onBack: ->

  showServicesOfferedInSelectedLocation: (locationDataObject)->
    locationAppointmentTypes = locationDataObject.data('locationServices')
    for locationAppointmentType in locationAppointmentTypes
      $(".bookings-section--service .module__item[data-appointment-type-id=#{locationAppointmentType}]").removeClass 'hidden'

  hideEmptyCategories: ->
    $('.bookings-section--service .module').each (index, category)=>
      $(category).toggleClass 'hidden', $(category).find(".module__item:not('*.hidden')").length == 0

  expandSingleCategories: ->
    visibleCategories = $(".bookings-section--service .module:visible")
    if visibleCategories.length == 1
      visibleCategories.find('.category-expand-toggle')
        .addClass('is-expanded')
        .next('ul')
        .removeClass('is-collapsed')

  clearServices: ->
    $('.bookings-section--service .module__item').addClass 'hidden'
    $('.bookings-section--service .module').addClass 'hidden'

  isMoreThanOneLocationAvailable: ->
    $('.bookings-section--location .module__item').length > 1

$('.button--reveal-map').on "click", (e)=>
  $(e.currentTarget).toggleClass('active')
  $(e.currentTarget).prev(".module__map").toggleClass('active')
  window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

$('.button--reveal-map').on "mouseup", (e)=>
  $(e.currentTarget).blur()
  window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')
